
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// this line ensures that the material theming only aplies to the snack-bar component
// we don't want it to apply to the material dialog component for example
@include mat-snack-bar-theme($app-theme);

@import '~@angular/material/theming';
@include mat-core();

.custom-material-styles {
  .modal_body {
    max-height: calc(95vh - 78px);
    overflow: auto;
  }
  .mat-dialog-container {
    padding: 0;

    &:not([class*='bg-']) {
      background-color: white;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle,
  &.mat-progress-spinner circle,
  &.mat-spinner circle {
    stroke: #000;
  }

  .mat-dialog-actions {
    margin-bottom: 0;
    padding: inherit;
  }
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  font-size: 12px;
}

.heading {
  @apply uppercase font-bold;
}

.geothermie_cluster {
  * {
    color: #fff !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

app-root {
  max-height: 100vh;
  height: 100vh;
  position: relative;
  display: block;
  overflow: hidden;
}

.btn {
  @apply font-bold uppercase py-2 px-4 rounded-lg;
  @apply transition;

  &[disabled] {
    @apply bg-opacity-80;
  }

  &:not([class*='bg-']) {
    @apply bg-brand-blue-500 hover:bg-brand-blue-600 text-white;
    &[disabled] {
      @apply bg-opacity-80;
    }
  }
}

.link {
  @apply text-brand-blue hover:text-brand-blue-400 transition duration-300 underline;
}

.tno-checkbox,
.tno-radio {
  position: relative;

  input {
    @apply hidden;
  }

  input:checked + label::before {
    @apply bg-brand-blue;
  }
}

.tno-checkbox {
  label{
    &::before {
      content: '';
      @apply w-4 h-4 mr-3 block rounded-sm bg-gray-100;
    }
  }
}

.tno-radio {
  label{
    &::before {
      content: '';
      @apply w-4 h-4 mr-3 block rounded-full bg-gray-100;
    }
  }
}

.tno-select {
  @apply rounded-md text-black ring-brand-blue;

  &.empty {
    @apply text-gray-700;
  }
}

.cluster {
  img {
    filter: brightness(0) invert(1);
  }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


img[src*='rvo.b3p.nl']
{
  opacity:0.8;
}
